import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/web/active/infoamazonia-mercurio/src/components/reports/report-layout.js";
import Image from "!url-loader!../../images/fotos/suriname-900.jpg";
export const meta = {
  name: "suriname",
  title: "The Gold Rush threatening the World's greenest country",
  intro: "Suriname’s waterways and trade winds carry mercury, a toxic metal used in gold extraction, to regions far removed from the country’s gold mines.",
  by: "By Bram Ebus (texts and photos) and Wilfred Leeuwin (texts). Vídeos by Tom Laffay",
  image: Image,
  next: {
    slug: "venezuela",
    title: "On the shores of Cuyuní, Mercury shines brighter than gold",
    description: "The closer you get to Guyana, to the east, the cheaper you will find the sought-after ‘quicksilver.’ The opening of an air route last year between the capital Georgetown and the."
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const Video = makeShortcode("Video");
const Sidenote = makeShortcode("Sidenote");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Fast-paced surinamese men`}</strong>{` carrying sports bags heavy with gold rush through the entrance of one of the gold shops in the center of Paramaribo, the capital city of Suriname. The lookout, a man in sunglasses and shorts, watches from the parking lot, his hand resting on the crossbody bag fastened across his chest. Inside, Chinese, Portuguese, Surinamese, and Dutch can all be overheard as kilograms of gold change hands. No one asks the men where the gold was mined before they leave the shop, bags now heavy with cash.`}</p>
    <p>{`At the counter, Harry Souza*, a muscular Brazilian man, shows off his tattoos and a small metal bucket full of gold bars in different sizes and colors. There are even some “sponges,” another name for the amalgam of mercury and gold that comes straight from the mines and looks like a luxurious coral reef. “The redder it is, the higher the quality,” says Souza. “The greener or blacker, the less `}{`[valuable]`}{`.” In a backroom of the gold shop, a blowtorch is spitting flames, and Souza heads back to burn off the surplus mercury under an extractor hood. The amount of gold left behind in the bucket weighs around one kilogram—a market value of more than US$ 62,000 (as on September 4)`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAECAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAeFfmlKhCf/EABYQAAMAAAAAAAAAAAAAAAAAAAAgMv/aAAgBAQABBQIlf//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ARn/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFJ/8QAFxABAAMAAAAAAAAAAAAAAAAAAQAgIf/aAAgBAQAGPwKaFf/EABoQAAMAAwEAAAAAAAAAAAAAAAABERAhMYH/2gAIAQEAAT8hk4NtFVfRvfEUuP/aAAwDAQACAAMAAAAQzP8A/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Qir//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxCiP//EABoQAQEBAQADAAAAAAAAAAAAAAERACFBUWH/2gAIAQEAAT8QEirTp5MEx1HmGtIfDXd6Zl3/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a4264e07ca9280913466a07557b380e2/e93cc/1.webp 300w", "/static/a4264e07ca9280913466a07557b380e2/b0544/1.webp 600w", "/static/a4264e07ca9280913466a07557b380e2/68fc1/1.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a4264e07ca9280913466a07557b380e2/73b64/1.jpg 300w", "/static/a4264e07ca9280913466a07557b380e2/3ad8d/1.jpg 600w", "/static/a4264e07ca9280913466a07557b380e2/6c738/1.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a4264e07ca9280913466a07557b380e2/6c738/1.jpg",
              "alt": "Gold mining is the driving force of the Surinamese economy",
              "title": "Gold mining is the driving force of the Surinamese economy",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Gold mining is the driving force of the Surinamese economy`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB41USNyMR/8QAGRAAAwADAAAAAAAAAAAAAAAAAAECAxAR/9oACAEBAAEFAlDZDnEibqRvuv/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ARn/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BTZ//xAAaEAACAgMAAAAAAAAAAAAAAAABIQIQABFB/9oACAEBAAY/Ak8nGYZGnykb/8QAHBABAAEEAwAAAAAAAAAAAAAAAQAQESFhMVFx/9oACAEBAAE/IeMK0TqswoQu55FyYOif/9oADAMBAAIAAwAAABDLP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EIH/xAAVEQEBAAAAAAAAAAAAAAAAAAAQMf/aAAgBAgEBPxCA/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARMSFhUXGB/9oACAEBAAE/EHAW4WEcy4Cq68RRV7liGGqou8i6vK/YIVd7J//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f59851e8320e6e8a295af8ef7f22cf0c/e93cc/2.webp 300w", "/static/f59851e8320e6e8a295af8ef7f22cf0c/b0544/2.webp 600w", "/static/f59851e8320e6e8a295af8ef7f22cf0c/68fc1/2.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f59851e8320e6e8a295af8ef7f22cf0c/73b64/2.jpg 300w", "/static/f59851e8320e6e8a295af8ef7f22cf0c/3ad8d/2.jpg 600w", "/static/f59851e8320e6e8a295af8ef7f22cf0c/6c738/2.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f59851e8320e6e8a295af8ef7f22cf0c/6c738/2.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHNUZzOaK//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQISIQP/2gAIAQEAAQUC2TXKpYkR0aTP/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEhMf/aAAgBAwEBPwGMU//EABYRAAMAAAAAAAAAAAAAAAAAAAIQMf/aAAgBAgEBPwEqv//EABoQAAICAwAAAAAAAAAAAAAAAAARAjEBECH/2gAIAQEABj8CVDlLfcFI/8QAGhABAAMAAwAAAAAAAAAAAAAAAQARITFBYf/aAAgBAQABPyG8IMxUiO+YKLGpTYL7EsPTJ//aAAwDAQACAAMAAAAQ9N//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/ECHFs//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EEs//8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBYXH/2gAIAQEAAT8QEigXnKwDZJsQr1uBYAg4Q3EN6DZ2J6y+oeRgw6rs/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1955505b6f7aa1de89f88179df623995/e93cc/DSC07454.webp 300w", "/static/1955505b6f7aa1de89f88179df623995/b0544/DSC07454.webp 600w", "/static/1955505b6f7aa1de89f88179df623995/68fc1/DSC07454.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1955505b6f7aa1de89f88179df623995/73b64/DSC07454.jpg 300w", "/static/1955505b6f7aa1de89f88179df623995/3ad8d/DSC07454.jpg 600w", "/static/1955505b6f7aa1de89f88179df623995/6c738/DSC07454.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1955505b6f7aa1de89f88179df623995/6c738/DSC07454.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHP2i4BFf/EABkQAAIDAQAAAAAAAAAAAAAAAAECAAMQEf/aAAgBAQABBQKLW7jOnP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABcRAAMBAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQIBAT8BNxf/xAAYEAACAwAAAAAAAAAAAAAAAAAAEAEiMf/aAAgBAQAGPwIrD1f/xAAZEAEAAwEBAAAAAAAAAAAAAAABABARMYH/2gAIAQEAAT8hm4VOTjQBgj2v/9oADAMBAAIAAwAAABD0z//EABcRAAMBAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQMBAT8QE1f/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPxCA/8QAGhABAQACAwAAAAAAAAAAAAAAAREAECExQf/aAAgBAQABPxDjHNqAh7iKDKM06EPYIuv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1fd95edea965a3eb4196ffd09162346f/e93cc/4.webp 300w", "/static/1fd95edea965a3eb4196ffd09162346f/b0544/4.webp 600w", "/static/1fd95edea965a3eb4196ffd09162346f/68fc1/4.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1fd95edea965a3eb4196ffd09162346f/73b64/4.jpg 300w", "/static/1fd95edea965a3eb4196ffd09162346f/3ad8d/4.jpg 600w", "/static/1fd95edea965a3eb4196ffd09162346f/6c738/4.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1fd95edea965a3eb4196ffd09162346f/6c738/4.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAci0GJCh/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECEQMx/9oACAEBAAEFApnS4xeHOmm2zT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAEAAgMAAAAAAAAAAAAAAAAAETEBECH/2gAIAQEABj8CVGVuJ1//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhQVFxkf/aAAgBAQABPyEsfKmAntILgR5HSqairSrL8T//2gAMAwEAAgADAAAAEFMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBkf/aAAgBAQABPxAEqquBsBOoaD84ksgV+qJXgFsESHVY3vBP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bf4a286d8b2c8d7f810a0a89817d0221/e93cc/5.webp 300w", "/static/bf4a286d8b2c8d7f810a0a89817d0221/b0544/5.webp 600w", "/static/bf4a286d8b2c8d7f810a0a89817d0221/68fc1/5.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bf4a286d8b2c8d7f810a0a89817d0221/73b64/5.jpg 300w", "/static/bf4a286d8b2c8d7f810a0a89817d0221/3ad8d/5.jpg 600w", "/static/bf4a286d8b2c8d7f810a0a89817d0221/6c738/5.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bf4a286d8b2c8d7f810a0a89817d0221/6c738/5.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`Gold mining is the driving force of the Surinamese economy, a small country in the northeast corner of South America. On paper,Suriname produced about 32,800 kilograms of gold in 2019, and more than half of it came from artisanal and small-scale mining. Gold accounts for more than 80 percent of Suriname’s income from exports.`}</p>
    <p>{`Much like in Guyana, Suriname's gold industry is propped up by a black market for mercury, the toxic metal used in the extraction process. The country uses over 50 tonnes of mercury a year, and experts believe all of it now enters the country illegally. Mercury pollution is rampant throughout the country, from the capital city on the coast to remote river basins in the interior, but without assistance from the government, small scale miners must often choose between supporting mercury trafficking networks or losing their livelihoods.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Suriname's gold industry is propped up by a black market for mercury. The country uses 50 tonnes a year, and experts believe all of it enters the country illegally."`}</p>
    </blockquote>
    <iframe src="https://datawrapper.dwcdn.net/bILLT/2/"></iframe>
    <hr></hr>
    <p>{`In satellite images, Suriname is almost entirely green. The jungle covers 93 percent of the country, making it the most heavily forested nation in the world. But a modern gold rush is threatening the country’s lush green forests.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Legal and illegal gold mining projects have become the biggest contributor to deforestation in Suriname.`}</p>
    </blockquote>
    <p>{`Throughout the rainforest, many communities depend on mining, including the Maroons, the descendents of African slaves who escaped from Dutch plantations on the coast and settled in the interior, who have strong cultural connections with mining and gold. The spike in gold prices at the beginning of the 21st century has led to a rapid expansion of the gold mining industry, especially in Maroon territory. Legal and illegal gold mining projects have become the biggest contributor to deforestation in Suriname. Yearly deforestation in Suriname increased with 12 percent in 2018, which was the fifth highest increased deforestation rate in the world`}</p>
    <Video url="https://youtube.com/embed/B-6C1u8KqHw" mdxType="Video" />
    <p>{`The industry’s footprint is greatest in easily accessible settlements like Brownsweg, a small Maroon village in Suriname’s interior. A majority of the population is involved in mining. At night, a few wealthy miners show up to party in front of the Chinese supermarkets and liquor stores, drifting 360s with Lexusses in the jungle. But most are subsistence miners and scramble to make ends meet.`}</p>
    <p>{`At a mining site an hour outside of Brownsweg, one of the local Maroon community leaders, called captains, walks around the ochre-colored craters that scar the green jungle. Sporting flip flops and a Pangi, the traditional Maroon cloth worn over his right shoulder, “Capy” explains that his miners use heavy machinery to dig the pits, but need mercury to separate the gold from the mud.`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdsljlqI4//EABsQAAIDAAMAAAAAAAAAAAAAAAADAQIRBBMj/9oACAEBAAEFAnM86tsq082wyew2TD//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAgEFAQAAAAAAAAAAAAAAAAFBEBESIVGR/9oACAEBAAY/Ankl6cINwWp//8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUWH/2gAIAQEAAT8hA5iVnaLAovhkA4RewHwTJtYTZdz/2gAMAwEAAgADAAAAEOw//8QAFxEAAwEAAAAAAAAAAAAAAAAAACExUf/aAAgBAwEBPxBKLD//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQIBAT8Qta//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMVGB/9oACAEBAAE/EA1Jwdq5Qw7dlRs5XyHSqBvbfexNHCmBm8jckhoWwgCsLJ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ebb05ccd492c268a66c762bf7d8cafa3/e93cc/slider2-1.webp 300w", "/static/ebb05ccd492c268a66c762bf7d8cafa3/b0544/slider2-1.webp 600w", "/static/ebb05ccd492c268a66c762bf7d8cafa3/68fc1/slider2-1.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ebb05ccd492c268a66c762bf7d8cafa3/73b64/slider2-1.jpg 300w", "/static/ebb05ccd492c268a66c762bf7d8cafa3/3ad8d/slider2-1.jpg 600w", "/static/ebb05ccd492c268a66c762bf7d8cafa3/6c738/slider2-1.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ebb05ccd492c268a66c762bf7d8cafa3/6c738/slider2-1.jpg",
              "alt": "In easily accessible settlements like Brownsweg, a small Maroon village in Suriname’s interior, the majority of the population is involved in mining",
              "title": "In easily accessible settlements like Brownsweg, a small Maroon village in Suriname’s interior, the majority of the population is involved in mining",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`In easily accessible settlements like Brownsweg, a small Maroon village in Suriname’s interior. A majority of the population is involved in mining`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAEU5uyaSGH/xAAbEAADAAIDAAAAAAAAAAAAAAAAAQIDEgQTFP/aAAgBAQABBQL12Lk2duVikl6m7P/EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPwGJj//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAgEBPwGrr//EABwQAAIABwAAAAAAAAAAAAAAAAAQASExQlGBkf/aAAgBAQAGPwK0pDhjSkv/xAAcEAEBAAICAwAAAAAAAAAAAAABABEhMUFRgbH/2gAIAQEAAT8hGQ+JOlvadwwPEFecSHrKX//aAAwDAQACAAMAAAAQxO//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxALL//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EFkv/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBcZHR8f/aAAgBAQABPxBaAU3f3EEOF4THmL2o18WYEqbrmKhYD3Fbd9z/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b1d5a36bb569a43f9c3403275a2f4e34/e93cc/slider2-2.webp 300w", "/static/b1d5a36bb569a43f9c3403275a2f4e34/b0544/slider2-2.webp 600w", "/static/b1d5a36bb569a43f9c3403275a2f4e34/68fc1/slider2-2.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b1d5a36bb569a43f9c3403275a2f4e34/73b64/slider2-2.jpg 300w", "/static/b1d5a36bb569a43f9c3403275a2f4e34/3ad8d/slider2-2.jpg 600w", "/static/b1d5a36bb569a43f9c3403275a2f4e34/6c738/slider2-2.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b1d5a36bb569a43f9c3403275a2f4e34/6c738/slider2-2.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAGZrmSrAaIn/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACEiEx/9oACAEBAAEFAihMgFOEfNtZ/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bh//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAAxARAhEf/aAAgBAQAGPwJCIysk4z//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhMVFx/9oACAEBAAE/Ia8LLxyWJRsniBGUSgYYbSf/2gAMAwEAAgADAAAAEKv/AP/EABcRAAMBAAAAAAAAAAAAAAAAAAABEWH/2gAIAQMBAT8QSQmH/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/EKx//8QAGhABAQEAAwEAAAAAAAAAAAAAAREAITGRsf/aAAgBAQABPxCdeU9140E9PNRHUWT5mPQOvmam5aF1GIDgV3//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/7e7ac88459187d99aaa9ace549d0e89b/e93cc/slider2-3.webp 300w", "/static/7e7ac88459187d99aaa9ace549d0e89b/b0544/slider2-3.webp 600w", "/static/7e7ac88459187d99aaa9ace549d0e89b/68fc1/slider2-3.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/7e7ac88459187d99aaa9ace549d0e89b/73b64/slider2-3.jpg 300w", "/static/7e7ac88459187d99aaa9ace549d0e89b/3ad8d/slider2-3.jpg 600w", "/static/7e7ac88459187d99aaa9ace549d0e89b/6c738/slider2-3.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7e7ac88459187d99aaa9ace549d0e89b/6c738/slider2-3.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABmzMIYLKf/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECAxET/9oACAEBAAEFAujO2krZJtFsEbh//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAS/9oACAEDAQE/AcBZL//EABcRAAMBAAAAAAAAAAAAAAAAAAABEgL/2gAIAQIBAT8BtlaP/8QAHRAAAQIHAAAAAAAAAAAAAAAAAAIREBIhMkGRof/aAAgBAQAGPwKqOjS7LUwyMf/EABoQAQACAwEAAAAAAAAAAAAAAAEAESExcZH/2gAIAQEAAT8hvIKEBxFOr9ZabikVUwm3Z//aAAwDAQACAAMAAAAQU+//xAAWEQADAAAAAAAAAAAAAAAAAAABECH/2gAIAQMBAT8QNCv/xAAXEQEAAwAAAAAAAAAAAAAAAAAAESFh/9oACAECAQE/EJ6bP//EAB0QAQEAAgIDAQAAAAAAAAAAAAERACExQVFhgaH/2gAIAQEAAT8QrNY9cc2TPIHuc45VBYMPtwZ6PbDeL/GkoB+YMgCGnln/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/357b3d16b1f9dcbf61dd30c120d707c9/e93cc/slider2-4.webp 300w", "/static/357b3d16b1f9dcbf61dd30c120d707c9/b0544/slider2-4.webp 600w", "/static/357b3d16b1f9dcbf61dd30c120d707c9/68fc1/slider2-4.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/357b3d16b1f9dcbf61dd30c120d707c9/73b64/slider2-4.jpg 300w", "/static/357b3d16b1f9dcbf61dd30c120d707c9/3ad8d/slider2-4.jpg 600w", "/static/357b3d16b1f9dcbf61dd30c120d707c9/6c738/slider2-4.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/357b3d16b1f9dcbf61dd30c120d707c9/6c738/slider2-4.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABrKec04JB/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAESAhET/9oACAEBAAEFAvXZe2OyelMeun//xAAXEQEAAwAAAAAAAAAAAAAAAAAAARIh/9oACAEDAQE/Aawx/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAESIf/aAAgBAgEBPwG0tf/EABkQAAIDAQAAAAAAAAAAAAAAAAABIDIzQv/aAAgBAQAGPwKqRyjSH//EABoQAQADAAMAAAAAAAAAAAAAAAEAESExUWH/2gAIAQEAAT8h1OV3EV3tRkpdB5UB5e5iF8NxVbP/2gAMAwEAAgADAAAAEJAP/8QAFxEBAAMAAAAAAAAAAAAAAAAAABFRYf/aAAgBAwEBPxDBFH//xAAYEQACAwAAAAAAAAAAAAAAAAAAARFBUf/aAAgBAgEBPxBOsnZ//8QAGxABAQACAwEAAAAAAAAAAAAAAREAITFBUaH/2gAIAQEAAT8QqXKwtVl5zkZUU18xtEexE+YUpQ32wl+vXezJ93Pc/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1211945c12409677b43af1b702c5ee14/e93cc/slider2-5.webp 300w", "/static/1211945c12409677b43af1b702c5ee14/b0544/slider2-5.webp 600w", "/static/1211945c12409677b43af1b702c5ee14/68fc1/slider2-5.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1211945c12409677b43af1b702c5ee14/73b64/slider2-5.jpg 300w", "/static/1211945c12409677b43af1b702c5ee14/3ad8d/slider2-5.jpg 600w", "/static/1211945c12409677b43af1b702c5ee14/6c738/slider2-5.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1211945c12409677b43af1b702c5ee14/6c738/slider2-5.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <Sidenote side="left" title="" mdxType="Sidenote">
	About 98 percent of the miners in Suriname use mercury, which binds to small flecks of gold mixed in with the water and mud that’s flushed from the pits.
    </Sidenote>
    <p>{`It is the mercury that greases the wheels of the local mining economies. About 98 percent of the miners in Suriname use mercury, which binds to small flecks of gold mixed in with the water and mud that’s flushed from the pits. Without the liquid quicksilver, the artisanal mines would be unable to efficiently extract the gold locked in the jungle’s soil, according to Jessica Naarendorp, the chief financial officer of NANA Resources, a Surinamese company that previously mined gold, but now mainly explores for national and foreign mining companies.`}</p>
    <p>{`For every kilogram of gold that’s extracted, roughly three kilograms of mercury are used, most of which is released into the fragile Amazon ecosystem.`}</p>
    <hr></hr>
    <p>{`More than 100 kilometers to the north of Capy’s mine, Paul Ouboter, a biologist and the director of the `}<a parentName="p" {...{
        "href": "https://www.neowild.org/"
      }}>{`Institute for Neotropical Wildlife and Environmental Studies`}</a>{` (also known as NeoWild) in Suriname, sits on the terrace of his house in Paramaribo. He traces the path of the country’s waterways on the maps on his laptop screen. Much of the mercury released by the mining sector ends up accumulating in the region’s rivers, according to Ouboter. From there, it travels up the food chain. Indigenous communities like the Maroon, who rely on fish for a large portion of their diet, are exposed to particularly high mercury levels.`}</p>
    <Video url="https://youtube.com/embed/-0sUAudv6CA" mdxType="Video" />
    <p>{`Research shows that nearly half of wild-caught, predatoryfish in Suriname have elevated levels of mercury. “That’s a problem because people like to eat big fish,” he grins. “It’s not easy to change people's habits in eating, but that’s what we have to do.” Giant wolffish, piranhas, and several catfish on the menu in Suriname should all be avoided, according to Ouboter.`}</p>
    <p>{`But mercury is not only transported through water and fish; it also travels through the air after evaporating from the surface of water and vegetation or after miners burn mercury off the amalgam on-site. “What happens then is that it goes with the northeastern trade winds to the west and then it is deposited again, especially when it rains,” says Ouboter. Airborne mercury can end up in regions without any gold mining,such as the upper Coppename river basin.`}</p>
    <p>{`Ouboter is calling for an immediate ban on mercury, though he cautions that it’s impossible to reverse the damage done to river systems. The mercury that’s in the ecosystem will remain there for centuries.`}</p>
    <hr></hr>
    <p>{`Capy, the gold miner in Brownsweg, is aware of the environmental impact of his operation, but claims it’s nothing compared to the international corporations that dig deeper than the small and medium-scale miners and apply toxic cyanide in their extraction process. Cyanide pollution, though not as persistent as mercury contamination, is still a major threat to wildlife and human health. “As I see it, the government has big interests in these large companies,” he says, “so they don't care.”`}</p>
    <Video url="https://youtube.com/embed/ii6Bi2Zy3Ng" mdxType="Video" />
    <p>{`The two big foreign mining multinationals in Suriname, IAMGOLD and Newmont Mining, use a process called cyanide heap leaching, which can extract finer concentrations of gold from the ground. With these advanced methods, the foreign investors can work through terrains previously mined by the Maroons in the interior, who can only trap about 30 percent of the available gold.`}</p>
    <p>{`The Maroon captain complains that the mines are not the only source of mercury pollution. The gold shops in Paramaribo, he argues, use the same techniques to burn off mercury and isolate gold. “In the city they think that we inland residents are not good, nothing we do is good,” he says. “But when we bring our gold to the city, the same thing happens there. And no one can tell me that it is not harmful there.”`}</p>
    <blockquote>
      <p parentName="blockquote">{`“In the city they think that we inland residents are not good, nothing we do is good,”`}</p>
    </blockquote>
    <hr></hr>
    <p>{`Suriname’s small but bustling capital Paramaribo is a melting pot of ethnicities. Old Dutch colonial houses and an international cuisine make the city a destination for tourists, mostly arriving on direct flights from The Netherlands. Despite its small population of 586,000, the country has no dominant ethnicity. Indian and Chinese laborers brought in after the end of slavery in 1863 now make up over a quarter of Suriname’s population. The official language is Dutch, and the common language is Sranan Tongo, a Dutch, English, and Portuguese creole, but Portuguese dominates in the northern part of the city, a neighborhood called Little Belem–named after a Brazilian city located over 1,000 km from Paramaribo.`}</p>
    <Video url="https://youtube.com/embed/R4kwzxuetgE" mdxType="Video" />
    <p>{`Little Belem features many Brazilian supermarkets and storefront shops advertising ‘compra de ouro’—Portuguese for “buying gold.” The migrant community was built on decades of labor migration by Brazilians looking for brighter economic prospects. Most of them found work as garimpeiros, illegal gold miners, or running the trade in Paramaribo’s gold shops, which pour mercury vapor into the air above the city from rooftop chimneys.`}</p>
    <p>{`Dennis Wip, a senior lecturer at the University of Suriname who specializes in mercury pollution, warns that these chimneys can be slow killers.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“In Paramaribo we have seen measurements that are six to twelve times higher than in the goldfields`}</p>
    </blockquote>
    <p>{`“Mercury pollution is a big issue in Suriname,” says Wip, and not only at remote mining sites, as many people assume. “In the city of Paramaribo the pollution is quite high,” he says. Emissions from the gold shops on occasion drive atmospheric mercury levels in the city to 30,000 nanograms per cubic meter, significantly higher than standards in the European Union and United States deem safe, according to the scientist. “In Paramaribo we have seen measurements that are six to twelve times higher than in the goldfields,” he says. “That means that in town we need to take care of the gold business.”`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB4zdxiSL/AP/EABgQAAMBAQAAAAAAAAAAAAAAAAABAhEi/9oACAEBAAEFAsTdYLCUVHOH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BV//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAAQESH/2gAIAQEABj8CMdq//8QAGhAAAwEAAwAAAAAAAAAAAAAAAAERMWFxof/aAAgBAQABPyFVU89HibEjDlIJodj/2gAMAwEAAgADAAAAEPvf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFR/9oACAEDAQE/EEY//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QR//EABsQAQEBAAMBAQAAAAAAAAAAAAERADFBUSFx/9oACAEBAAE/EKoHKIv5Jn0JRzo9S9t5xqxCRHzAUkemV8nXf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/eb9ccd46747fc591122a9a0c36a72dcf/e93cc/DSC07471.webp 300w", "/static/eb9ccd46747fc591122a9a0c36a72dcf/b0544/DSC07471.webp 600w", "/static/eb9ccd46747fc591122a9a0c36a72dcf/68fc1/DSC07471.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/eb9ccd46747fc591122a9a0c36a72dcf/73b64/DSC07471.jpg 300w", "/static/eb9ccd46747fc591122a9a0c36a72dcf/3ad8d/DSC07471.jpg 600w", "/static/eb9ccd46747fc591122a9a0c36a72dcf/6c738/DSC07471.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/eb9ccd46747fc591122a9a0c36a72dcf/6c738/DSC07471.jpg",
            "alt": "Emissions from the gold shops have elevated atmospheric mercury levels in Paramaribo",
            "title": "Emissions from the gold shops have elevated atmospheric mercury levels in Paramaribo",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Emissions from the gold shops on occasion drive atmospheric mercury levels in Paramaribo`}</em></p>
    <p>{`Equipment in the gold shops, especially the retorts, which are mercury capture devices, are often dysfunctional or in bad shape, according to Wip. Most of the gold shops employees consulted for this report were either not aware of the harms of mercury contamination or downplayed the impact, which experts blame on the “macho culture” of the industry, lack of access to medical remedies and the slow-acting nature of the toxin.`}</p>
    <p>{`Without better equipment and education about the risks, mercury emissions in Paramaribo could continue to increase as gold prices continue to rise, and more of the precious metal makes its way into the capital’s gold shops.`}</p>
    <hr></hr>
    <p>{`In 2018, Suriname ratified the Minamata Convention on Mercury, a global treaty designed to reduce mercury pollution, in part by eliminating its use in gold mining. But mercury continues to pour in across its borders, mostly through illegal channels. While mercury can be legally imported with a license, no permits have been granted since 2004.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.iucn.nl/-files/groene_economie/regional_mercury_report_suriname.pdf"
      }}>{`An estimated 56 tonnes`}</a>{` of mercury were used in Suriname in 2018, the most recent year for which data is available, and various mining hardware stores, run by Brazilians, Indians, and Chinese owners, confirm that they can set a client up with the liquid quicksilver. "The wide availability of mercury in Suriname and the fact that no single legal mercury import took place in over 15 years gives rise to the suspicion that all the mercury on the market entered the country illegally," says Marieke Heemskerk, an anthropologist with a long track record investigating the Surinamese gold sector.`}</p>
    <p>{`None of the store owners would divulge how they acquired the quicksilver in the first place, but it turns out it's not hard to find if you know who to ask.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAeLFbHImR//EABgQAQEBAQEAAAAAAAAAAAAAAAECEgAR/9oACAEBAAEFApjUO5PThzS+yrPf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERIUEQIv/aAAgBAQAGPwLy+WmVo5xkJn//xAAaEAEAAgMBAAAAAAAAAAAAAAABACERMVFh/9oACAEBAAE/IcMgMxnl27Jxb5EJTayPVPY3GcrG5//aAAwDAQACAAMAAAAQE/8A/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QV//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCVj//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExQVFhof/aAAgBAQABPxBzIcnnDe5WgSEzUaNsNHB/iAP3J5WVDRZk+DIHr1n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/b7982542053e6174008af7df5977506e/e93cc/DSC07613.webp 300w", "/static/b7982542053e6174008af7df5977506e/b0544/DSC07613.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/b7982542053e6174008af7df5977506e/73b64/DSC07613.jpg 300w", "/static/b7982542053e6174008af7df5977506e/3ad8d/DSC07613.jpg 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b7982542053e6174008af7df5977506e/3ad8d/DSC07613.jpg",
            "alt": "Wrapped inside a newspaper a mercury dealer reveals a small, white bottle filled with a heavy and silvery liquid. “It comes from Guyana,” the man says.",
            "title": "Wrapped inside a newspaper a mercury dealer reveals a small, white bottle filled with a heavy and silvery liquid. “It comes from Guyana,” the man says.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Wrapped inside a newspaper a mercury dealer reveals a small, white bottle filled with a heavy and silvery liquid. “It comes from Guyana,” the man says.`}</em></p>
    <p>{`At around six in the afternoon, the bars near the gold shops of Little Belem and the streets of Paramaribo start to get noisier as the sun sets over the city. An Indian cab driver sips nervously from a half-liter can of Heineken beer while lingering next to his cab on the Anamoestraat, the main street in Little Belem. When asked about the liquid quicksilver, he offers to drive to a nearby gas station to make a quick phone call to a dealer. He hangs up and announces that a kilogram of mercury will cost $110, and that a seller is on the way.`}</p>
    <p>{`Within a few minutes another car appears. The driver rolls down his window and holds up a rolled-up newspaper that he brought with him. “It comes from Guyana,” the man says. Wrapped inside is a small, white bottle filled with a heavy, silvery liquid. The dealer unscrews the plastic bottle and pours a tiny amount of quicksilver into the bottle cap to prove that he has the product. When asked how much more mercury he could get, he asks: “How much would you want?” There seems to be no shortage of it, despite the fact that not a single shipment of mercury has been seized on route from Guyana since 2014.`}</p>
    <hr></hr>
    <p>{`Not all of the illicit mercury in the country comes from Guyana, according to a source in Paramaribo, who agreed to an interview on the condition that he only be identified as an anonymous “gold entrepreneur.” On regular trips south, to work in the mines in the country's interior, he's learned that there are other routes by which mercury enters the country. During an interview, he calls his Chinese-Surinamese, mining-equipment trader for more information.`}</p>
    <p>{`The Chinese-Surinamese trader claims that the Chinese provide most of the country’s mercury now. Shops buy containers of the mercury for $3000 USD and sell it for $3200. “If it comes from China, it only needs to pass through one location,” the entrepreneur says. “The `}{`[sea]`}{`port.”`}</p>
    <p>{`A 2020 study from the International Union for Conservation of Nature National Committee of the Netherlands `}<a parentName="p" {...{
        "href": "https://www.iucn.nl/-files/groene_economie/lr_mercury_brochure_digitaal_gebruik.pdf"
      }}>{`(IUCN NL)`}</a>{` found that no illegal mercury shipments from China have been intercepted, but informants told investigators that customs officials are commonly bribed to let illicit goods enter the country.`}</p>
    <p>{`Back on “the backtrack,” the informal river crossings between Guyana and Suriname, a Surinamese boat-trafficker looks out over the Corentyne River near Nieuw Nickerie. He claims that mercury is taken from Suriname to Guyana, and that the contraband routes are very fugacious. He carried mercury across the river himself in the opposite direction, from Guyana to Suriname, in industrial-sized packaging until about five months ago. “The people buy the big bottles … and they pack it in smaller bottles to trade and sell it quicker,” the trafficker says.`}</p>
    <Video url="https://youtube.com/embed/wlKJb1KKJCc" mdxType="Video" />
    <p>{`Corruption, fast-changing modus operandi, and a lack of resources frustrate some of Suriname’s well-intentioned law enforcement officers. “The border is open,” says a local officer in the Nieuw Nickerie municipality. Multiple crossing points provide ample opportunities for smugglers. On the Surinamese banks of the river, there are many informal inroads and creeks, facilitating an easy entrance. We cannot have eyes on all places at a time," he says, adding that smugglers are "becoming smarter and smarter.”`}</p>
    <blockquote>
      <p parentName="blockquote">{`We cannot have eyes on all places at a time," he says, adding that smugglers are "becoming smarter and smarter."`}</p>
    </blockquote>
    <hr></hr>
    <p>{`Capy, the Maroon mine owner, has run his mine near Brownsweg for 15 years. Just as most miners interviewed for this report, he hopes to abandon the mercury, but lacks proper guidance. “We are willing. If they say there is another way, we want to work with it,” Capy says. “But they `}{`[the government]`}{` say nothing; they teach us nothing.”`}</p>
    <p>{`The career miner’s argument is echoed by Naarendorp. “No one can be against Minamata,” she says, “but the moment you say we're going to ban something, you have to come up with an alternative.”`}</p>
    <Video url="https://youtube.com/embed/9uuO-lK0GnE" mdxType="Video" />
    <p>{`Many of the inland miners are hard workers, but uneducated, according to Naarendorp. She argues that the miners need not only to be educated, but also to be convinced about alternative or safe methods, which are often very costly or hard to access. “We all want to go-green,” she says, “but then it must be affordable.”`}</p>
    <p>{`Capy admits that they need to buy mercury on the black market. “There is no specific place where it can be bought, because it is prohibited, just like cocaine, marijuana, and ecstasy. This kind of thing you just find on the street because it is forbidden stuff.”`}</p>
    <p><em parentName="p">{`*`}{`Some names have been changed to protect the identities of interviewees.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      